
import { computed, defineComponent, inject } from 'vue'
import UploadImage from '@/components/UploadImage.vue'

export default defineComponent({
    emits: ['imagePath'],
    components: { UploadImage },
    setup(props, { emit }) {
        const authStore: any = inject('authState')
        const updateImagePath = (file: any) => {
            emit('imagePath', file)
        }

        return {
            userData: computed(() => authStore.state.userData),
            updateImagePath,
        }
    },
})
