
import { defineComponent, inject, reactive } from 'vue'
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n'
import i18n from '@/i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
    setup() {
        const configurationStore: any = inject('configurationStore');
        const authStore: any = inject('authState');
        const cancelAccountForm = reactive(configurationStore.state.cancelAcccount.cancelAccountForm);
        const router: any = useRouter()

        const cancelAccount = ()=>{
            cancelAccountForm.showError = false;
            Swal.fire({
                title: i18n.global.t('Client_settings_settingscontroller_close_acc'),
                text: i18n.global.t('tester_cancel_account_advert'),
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: i18n.global.t('Client_settings_settingscontroller_close_acc1'),
                denyButtonText: i18n.global.t('Client_settings_settingscontroller_go_back'),
            }).then(async(result) => {
                if (result.isConfirmed) {
                    const success = await configurationStore.deleteAccount();
                    if(success){
                        await authStore.logUserOut();
                        router.push( { name: 'login' } );
                    }else{
                        cancelAccountForm.showError = true;
                    }
                } else if (result.isDenied) {
                    return;
                }
            })
        }

        const closeCancelAccount =()=>{
            configurationStore.clearCancelAccount();
        }
        return{
            cancelAccountForm,
            closeCancelAccount,
            cancelAccount
        }
    },
})
